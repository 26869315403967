import { Box, Checkbox, Divider, Grid, Paper, Space, Stack, Text } from '@mantine/core';

import { LIGHT_GREY_BG } from 'constants/colors';
import { ANALYSIS, SAMPLING } from 'constants/pricing';

import { formatDateMonthDayYear } from 'util/date';
import useBroswerLanguage from 'util/hooks/useLanguage';
import { roundTwoDecimal } from 'util/numUtils';
import { FinancialTransactionGroupType } from 'store/payments/types';

import { useBillingTranslations } from '../../hooks';

import SamplingPlanTransactionRow from './SamplingPlanTransactionRow';

const NUMBER_HEADER_COLS = 8;
const NUMBER_PLAN_COLS = 8;

type Props = {
  transactionGroups: FinancialTransactionGroupType[];
  selectedPlanIds: number[];
  setSelectedPlanIds: (planIds: number[]) => void;
};

const SamplingPlanGrid = ({ transactionGroups, selectedPlanIds, setSelectedPlanIds }: Props) => {
  const language = useBroswerLanguage();
  const translations = useBillingTranslations();
  const columnNames = [
    translations.acres,
    translations.package,
    translations.status,
    translations.completed,
    translations.dealerPrice,
    translations.customerPrice,
  ];

  const addRemoveSelectedPlan = (planId: number) => {
    setSelectedPlanIds(
      selectedPlanIds.includes(planId)
        ? selectedPlanIds.filter((id) => id !== planId)
        : [...selectedPlanIds, planId],
    );
  };

  const getPackageName = (transactionGroup: FinancialTransactionGroupType) => {
    // First transaction will be the package name unless it is a custom analysis
    const firstTransaction = transactionGroup.transactions.find(
      (transaction) => transaction.sku_type === ANALYSIS,
    );
    return firstTransaction?.summary_json?.is_custom_analysis
      ? translations.custom
      : firstTransaction?.sku_name;
  };

  return (
    <>
      {transactionGroups.map((transactionGroup) => (
        <Paper key={transactionGroup.sampling_plan_id} bg={LIGHT_GREY_BG} p="xs">
          <Stack gap="lg">
            <Grid columns={NUMBER_HEADER_COLS} align="flex-end">
              <Grid.Col span={1}>
                <Stack gap={0}>
                  <Text size="xs">{translations.farm}</Text>
                  <Text size="sm" fw="bold">
                    {translations.field}
                  </Text>
                </Stack>
              </Grid.Col>
              {columnNames.map((name) => (
                <Grid.Col span={1} key={name}>
                  <Text size="sm" fw="bold">
                    {name}
                  </Text>
                </Grid.Col>
              ))}
            </Grid>
            <Divider size="sm" />
            <Grid columns={NUMBER_HEADER_COLS}>
              <Grid.Col span={1}>
                <Stack gap={0}>
                  <Text size="xs">{transactionGroup.farm_name}</Text>
                  <Text size="sm" fw="bold">
                    {transactionGroup.field_name}
                  </Text>
                </Stack>
              </Grid.Col>
              <Grid.Col span={1}>
                {roundTwoDecimal(transactionGroup.calculated_area_acres)}
              </Grid.Col>
              <Grid.Col span={1}>{getPackageName(transactionGroup)}</Grid.Col>
              <Grid.Col span={1}>{transactionGroup.status}</Grid.Col>
              <Grid.Col span={1}>
                {formatDateMonthDayYear(transactionGroup.earliest_created_at, language)}
              </Grid.Col>
              <Grid.Col span={1}>${roundTwoDecimal(transactionGroup.total_billed_price)}</Grid.Col>
              <Grid.Col span={1}>${roundTwoDecimal(transactionGroup.total_list_price)}</Grid.Col>
              <Grid.Col span={1}>
                <Checkbox
                  checked={selectedPlanIds.includes(transactionGroup.sampling_plan_id)}
                  onChange={() => addRemoveSelectedPlan(transactionGroup.sampling_plan_id)}
                />
              </Grid.Col>
            </Grid>
            <Space h="lg" />
            <Grid columns={NUMBER_PLAN_COLS} c="gray.7" p="lg">
              <Grid.Col span={1} offset={2}>
                {translations.zones}
              </Grid.Col>
              <Grid.Col span={1}>
                {translations.dealer} $/{translations.zone}
              </Grid.Col>
              <Grid.Col span={1}>
                {translations.dealer} $/{translations.acre}
              </Grid.Col>
              <Grid.Col span={1}>{translations.dealerTotal}</Grid.Col>
              <Grid.Col span={1}>{translations.customerTotal}</Grid.Col>
            </Grid>
            {[ANALYSIS, SAMPLING].map((skuType) => (
              <Box key={skuType}>
                <Grid columns={NUMBER_PLAN_COLS} c="gray.7" pl="lg">
                  <Grid.Col span={1} c="blue" fw="bold">
                    {translations[skuType]}
                  </Grid.Col>
                  <Grid.Col span={1} offset={4} fw="bold">
                    ${roundTwoDecimal(transactionGroup[`total_${skuType}_price`])}
                  </Grid.Col>
                  <Grid.Col span={1} fw="bold">
                    ${roundTwoDecimal(transactionGroup[`total_${skuType}_list_price`])}
                  </Grid.Col>
                </Grid>
                {transactionGroup.transactions
                  .filter((transaction) =>
                    skuType === ANALYSIS
                      ? transaction.sku_type === ANALYSIS
                      : transaction.sku_type !== ANALYSIS,
                  )
                  .map((transaction) => (
                    <SamplingPlanTransactionRow transaction={transaction} key={transaction.id} />
                  ))}
              </Box>
            ))}
          </Stack>
        </Paper>
      ))}
    </>
  );
};

export default SamplingPlanGrid;
