import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Input,
  List,
  Modal,
  Slider,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedCallback } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';
import AnalysisProductPrice from 'apps/ZoneAnalysisV3/Components/PlanSteps/AnalysisProductPrice';

import { COLORS, LIGHT_GREY_BG, UI_COLORS } from 'constants/colors';
import {
  ANALYSIS,
  SAMPLING,
  THREE_SIXTY_PRO_SKU_ID,
  TRUBIO_SKU_ID,
  TRUNUTRIENT_SKU_ID,
} from 'constants/pricing';
import { SKU_ABBREVIATIONS } from 'constants/totalFarm';

import { convertDecimalToPercent, getBeforeAndAfterDecimal, roundTwoDecimal } from 'util/numUtils';
import showToast from 'actions/toastActions';
import { requestPostTotalFarm } from 'store/operation/requests';
import { getOperation } from 'store/operation/thunks';
import { OperationType, TotalFarmSkuPriceEstimateType } from 'store/operation/types';

import { useEditOperationTranslations } from '../EditOperation/hooks';

import { useTotalFarmPriceQuery } from './queries';

type Props = {
  opened: boolean;
  onClose: VoidFunction;
  operation: OperationType;
};

type TotalFarmFormType = {
  truBioPercent: number;
  truNutrientPercent: number;
  totalFarmAcres: number;
};

const INPUT_WIDTH = '7rem';
const SLIDER_GROUP_WIDTH = '20rem';

const TRUBIO_LIST = [THREE_SIXTY_PRO_SKU_ID, TRUBIO_SKU_ID];
const TRUNUTRIENT_LIST = [THREE_SIXTY_PRO_SKU_ID, TRUNUTRIENT_SKU_ID];
const INITIAL_PERCENT = 0.25;

const TotalFarmModal = ({ opened, onClose, operation }: Props) => {
  const translations = useEditOperationTranslations();
  const dispatch = useDispatch();
  const [selectedSku, setSelectedSku] = useState(THREE_SIXTY_PRO_SKU_ID);
  const [totalFarmAcres, setTotalFarmAcres] = useState<number>(
    roundTwoDecimal(operation.total_acerage) || 0,
  );
  const [isViewDealerPrice, toggleIsViewDealerPrice] = useState(false);

  const form = useForm<TotalFarmFormType>({
    mode: 'uncontrolled',
    initialValues: {
      truBioPercent: INITIAL_PERCENT,
      truNutrientPercent: INITIAL_PERCENT,
      totalFarmAcres,
    },
  });
  const formValues = form.getValues();

  const { data, isPending, isError } = useTotalFarmPriceQuery({
    operationId: operation.id,
    ...form.getValues(),
    enabled: opened,
  });

  const totalFarmMutation = useMutation({
    mutationFn: () => (data ? requestPostTotalFarm(data) : Promise.resolve()),
    onSuccess: () => {
      onClose();
      dispatch(getOperation(operation.id));
      showToast(translations.totalFarmActivatedMsg, 'success');
    },
  });

  const firstSectionList = [
    { label: translations.fertilityRx, text: translations.highResNutrientScriptMsg },
    { label: translations.seedRx, text: translations.highResVariableRateScriptMsg },
    { label: translations.customRx, text: translations.customRxMsg },
  ];
  const secondSectionList = [
    {
      label: translations.premiumFeatures,
      text: translations.activeTodayGenerateUnlimitedMsg,
    },
    {
      label: translations.analysisOverYear,
      text: translations.executeTargetAnalysisMsg,
    },
    {
      label: translations.payAsYouGo,
      text: translations.payAsYouGoMsg,
    },
  ];

  const updateSelectedSku = (skuId: number) => {
    setSelectedSku(Number(skuId));
    form.setValues({
      truBioPercent: TRUBIO_LIST.includes(skuId) ? INITIAL_PERCENT : 0,
      truNutrientPercent: TRUNUTRIENT_LIST.includes(skuId) ? INITIAL_PERCENT : 0,
    });
  };

  const updateTotalFarmAcres = useDebouncedCallback((totalAcres: number) => {
    form.setFieldValue('totalFarmAcres', totalAcres);
  }, 500);

  const updateOperationTotalAcres = (e: React.ChangeEvent<HTMLInputElement>) => {
    const totalAcres = Number(e.target.value);
    updateTotalFarmAcres(totalAcres);
    setTotalFarmAcres(totalAcres);
  };

  const setupKeyValListItem = (key: string, value: string) => (
    <List.Item key={key}>
      <b>{key}:</b> {value}
    </List.Item>
  );

  const valueLabelFormat = (value: number | undefined) =>
    value ? `${convertDecimalToPercent(value, 0)}% (${Math.round(value * totalFarmAcres)} ac)` : '';

  const skuPercentSlider = (formPropType: string) => (
    <Slider
      color={UI_COLORS.darkBlue}
      w={INPUT_WIDTH}
      label={valueLabelFormat}
      min={0.25}
      max={1}
      step={0.05}
      {...form.getInputProps(formPropType)}
      onChange={(value) => form.setFieldValue(formPropType, value)}
    />
  );

  const agencyPriceBox = (() => {
    const selfServiceTotal = data?.sku_price_estimates.reduce(
      (total: number, skuPriceEstimate: TotalFarmSkuPriceEstimateType) => {
        skuPriceEstimate.sku_prices.forEach((skuPrice) => {
          if (skuPrice.sku_type === ANALYSIS) {
            total += skuPrice.total_price;
          }
        });
        return total;
      },
      0,
    );
    const selfSampleTotal = data?.sku_price_estimates.reduce(
      (total: number, skuPriceEstimate: TotalFarmSkuPriceEstimateType) => {
        skuPriceEstimate.sku_prices.forEach((skuPrice) => {
          if (skuPrice.sku_type !== SAMPLING) {
            total += skuPrice.total_price;
          }
        });
        return total;
      },
      0,
    );
    return {
      msrp: {
        perAcre: data?.total_estimated_list_price_per_acre || 0,
        total: data?.total_estimated_list_price || 0,
      },
      dealerFullService: {
        perAcre: data?.total_estimated_price_per_acre || 0,
        total: data?.total_estimated_price || 0,
      },
      dealerSelfSample: {
        perAcre: roundTwoDecimal((selfSampleTotal || 0) / totalFarmAcres),
        total: roundTwoDecimal(selfSampleTotal || 0),
      },
      dealerSelfService: {
        perAcre: roundTwoDecimal((selfServiceTotal || 0) / totalFarmAcres),
        total: roundTwoDecimal(selfServiceTotal || 0),
      },
    };
  })();

  const formattedPrice = getBeforeAndAfterDecimal(data?.total_estimated_list_price_per_acre || 0);
  return (
    <>
      <Modal opened={opened} onClose={onClose} centered size="77rem" px="md">
        <form onSubmit={form.onSubmit(() => totalFarmMutation.mutate())}>
          <Stack align="center" gap={0}>
            <Title order={1}>{translations.totalFarm}</Title>
            <Title order={3}>{translations.totalFarmOperationMsg}</Title>
          </Stack>
          <Space h="lg" />
          <Button.Group pl="lg">
            {Object.entries(SKU_ABBREVIATIONS).map(([skuId, skuValue]) => (
              <Button
                key={skuValue}
                onClick={() => updateSelectedSku(Number(skuId))}
                variant={selectedSku === Number(skuId) ? 'filled' : 'outline'}
              >
                {skuValue}
              </Button>
            ))}
          </Button.Group>
          <Grid p="lg">
            <Grid.Col span={7}>
              <Box>
                <Title order={4} fw="bold">
                  {translations.comprehensiveFieldAnalysisMsg}:
                </Title>
                <List listStyleType="disc">
                  <List.Item>
                    <b>{SKU_ABBREVIATIONS[TRUNUTRIENT_SKU_ID]}</b> -{' '}
                    {translations.highResSoilFertMsg}
                  </List.Item>
                  <List.Item>
                    <b>{SKU_ABBREVIATIONS[TRUBIO_SKU_ID]}</b> -{' '}
                    {translations.comprehensiveFieldBioMsg}
                  </List.Item>
                </List>
              </Box>
              <Space h="md" />
              <Box>
                <Title order={4} fw="bold">
                  {translations.keyOutcomeMsg}:
                </Title>
                <List listStyleType="disc">
                  {firstSectionList.map((item) => setupKeyValListItem(item.label, item.text))}
                </List>
              </Box>
              <Space h="md" />
              <Box>
                <Title order={4} fw="bold">
                  {translations.howItWorks}:
                </Title>
                <List listStyleType="disc">
                  {secondSectionList.map((item) => setupKeyValListItem(item.label, item.text))}
                </List>
              </Box>
            </Grid.Col>
            <Grid.Col span={5}>
              <Stack align="center" gap="xs">
                <Text size="xs" fw="bold">
                  {translations.percentOfFarmAnalyzedPerYear}:
                </Text>
                <Group justify="space-between" w={SLIDER_GROUP_WIDTH}>
                  <Text size="sm">{translations.totalFarmAcres}</Text>
                  <Input
                    w={INPUT_WIDTH}
                    value={totalFarmAcres}
                    onChange={updateOperationTotalAcres}
                  />
                </Group>
                {TRUBIO_LIST.includes(selectedSku) && (
                  <Group justify="space-between" w={SLIDER_GROUP_WIDTH}>
                    <Text size="sm">
                      TruBio / Yr ({convertDecimalToPercent(formValues.truBioPercent, 0)}%)
                    </Text>
                    {skuPercentSlider('truBioPercent')}
                  </Group>
                )}
                {TRUNUTRIENT_LIST.includes(selectedSku) && (
                  <Group justify="space-between" w={SLIDER_GROUP_WIDTH}>
                    <Text size="sm">
                      TruNutrient / Yr ({convertDecimalToPercent(formValues.truNutrientPercent, 0)}
                      %)
                    </Text>
                    {skuPercentSlider('truNutrientPercent')}
                  </Group>
                )}
                <AnalysisProductPrice
                  formattedPrice={{
                    beforeDecimal: formattedPrice?.beforeDecimal || '0',
                    afterDecimal: formattedPrice?.afterDecimal || 0,
                  }}
                  isPending={isPending}
                  error={null}
                  followingString=" per yr*"
                />
                <Text>
                  <b>{translations.estTotalFarmPrice}:</b> ${data?.total_estimated_list_price}*
                </Text>
                {!isViewDealerPrice && (
                  <Text
                    c={COLORS.lightBlue}
                    onClick={() => toggleIsViewDealerPrice(!isViewDealerPrice)}
                    style={{ cursor: 'pointer' }}
                  >
                    {translations.seeDealerPrice}
                  </Text>
                )}
              </Stack>
              {isViewDealerPrice && (
                <Group justify="center">
                  <Box w="20rem" style={{ border: `1px solid ${LIGHT_GREY_BG}` }}>
                    <Stack gap={0} p="md">
                      {Object.keys(agencyPriceBox).map((agencyPriceKey) => (
                        <Grid columns={4} key={agencyPriceKey}>
                          <Grid.Col span={2}>
                            <Text size="xs">{translations[agencyPriceKey]}: </Text>
                          </Grid.Col>
                          <Grid.Col span={2}>
                            <Text size="xs">
                              ${agencyPriceBox[agencyPriceKey].perAcre} / ac ($
                              {agencyPriceBox[agencyPriceKey].total})
                            </Text>
                          </Grid.Col>
                        </Grid>
                      ))}
                    </Stack>
                  </Box>
                </Group>
              )}
            </Grid.Col>
          </Grid>
          <Stack align="center" gap="sm">
            <Button
              color={UI_COLORS.warning}
              type="submit"
              disabled={
                isError ||
                isPending ||
                (!formValues.truBioPercent && !formValues.truNutrientPercent)
              }
              loading={totalFarmMutation.isPending}
            >
              {translations.activateTotalFarm}
            </Button>
            <Divider size="sm" />
            <Text size="xs">* {translations.subscriptionCoversAnalysisMsg}</Text>
          </Stack>
        </form>
      </Modal>
    </>
  );
};

export default TotalFarmModal;
