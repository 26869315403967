import { approvalStatusType } from 'constants/routes/financeRoutes';

import { GroupedCollectionsListPayload } from 'store/eoCollections/types';
import { SkuItemPayload } from 'store/pricing/types';
import { GetProjectsPayloadType } from 'store/projects/types';

export const DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS: GroupedCollectionsListPayload = {
  groupBy: 'operation',
  sortBy: 'name',
  page: 1,
  status: 'pending',
};

export const samplingPlansQueryKeys = {
  externalLab: (samplingPlanId: number) => ['externalLab', samplingPlanId],
  activeSamplingPlans: (fieldId: number) => ['activeSamplingPlans', fieldId],
  sampleTimings: ['sampleTimings'],
};

export const fieldsQueryKeys = {
  field: (fieldId: number) => ['field', fieldId],
};

export const operationsQueryKeys = {
  operations: ['operations'],
  operation: (operationId: number) => [...operationsQueryKeys.operations, operationId],
  operationUsers: (operationId: number) => ['operationUsers', operationId],
};

export const agenciesQueryKeys = {
  operationAgencies: ['operationAgencies'],
};

export const dataReviewQueryKeys = {
  groupedCollections: () => ['groupedCollections'],
  filteredGroupedCollections: (params = DEFAULT_COLLECTION_GROUP_QUERY_OPTIONS) => [
    ...dataReviewQueryKeys.groupedCollections(),
    { ...params },
  ],
  collectionsByField: (fieldId: number, status: GroupedCollectionsListPayload['status']) => [
    ...dataReviewQueryKeys.groupedCollections(),
    fieldId,
    status,
  ],
};

export const pricingQueryKeys = {
  allSkus: ['allSkus'],
  defaultPricing: ['defaultPricing'],
  externalLabs: ['externalLabs'],
  contractsByAgency: (agencyId: string) => ['contractsByAgency', agencyId],
  priceEstimate: (estimatePayload: {
    fieldId: number;
    skuPrices: SkuItemPayload[];
    isSplitDensity: boolean;
    sampledById: number;
    scannedById: number;
    primaryProductId: number;
    nutrientAnalysisPackageId: number | null;
  }) => ['priceEstimate', estimatePayload],
};

export const sampleQueryKeys = {
  sample: (sampleBarcode: string) => ['sample', sampleBarcode],
};

export const resultsQueryKeys = {
  /** List of available layers */
  samplePlanTillageLayers: (samplingPlanId?: number) => ['tillageLayers', samplingPlanId],
};

export const financialQueryKeys = {
  summaryTransactions: (approvalStatus: approvalStatusType) => [
    'summaryTransactions',
    approvalStatus,
  ],
  individualSummaryTransactions: (approvalStatus: approvalStatusType, userId: number) => [
    'individualSummaryTransactions',
    approvalStatus,
    userId,
  ],
  transactionsByUser: (approvalStatus: approvalStatusType, userId: number) => [
    'transactionsByUser',
    approvalStatus,
    userId,
  ],
  incompleteSalesOrders: ['incompleteSalesOrders'],
};

export const projectsQueryKeys = {
  defaultSamplingPlanConfig: ['defaultSamplingPlanConfig'],
  listSamplingPlans: ['samplingPlans'],
  listProjects: ['projects'],
  fieldGates: (fieldId: number) => ['fieldGates', fieldId],
  operations: (page: number) => ['project-operations', page],
  clus: (bounds: [number, number, number, number] | null) => ['clus', bounds ?? undefined],
  editProject: (projectId: number) => [...projectsQueryKeys.listProjects, projectId],
  samples: (samplingPlanId: number) => ['carbon-samples', samplingPlanId],
  projectSamplingPlans: (projectId: number) => ['projectSamplingPlans', projectId],
  projectSamplingPlanCount: (projectId: number) => ['projectSamplingPlanCount', projectId],
  paginatedProjects: (params?: GetProjectsPayloadType) => [
    ...projectsQueryKeys.listProjects,
    params,
  ],
  samplingPlanProgressLog: (samplingPlanId?: string) => [
    'carbon-sampling-plan-log',
    samplingPlanId ?? undefined,
  ],
  samplingPlan: (samplingPlanId: number) => [
    ...projectsQueryKeys.listSamplingPlans,
    'samplingPlan',
    samplingPlanId,
  ],
};

export const totalFarmQueryKeys = {
  operationPriceEstimate: (payload: {
    operationId: number;
    totalFarmAcres: number;
    truBioPercent: number;
    truNutrientPercent: number;
  }) => ['operationPriceEstimate', payload],
};

export const inputSearchKeys = {
  productSearch: (name: string, crop: string) => ['productSearch', name, crop],
  seedSearch: (name: string, crop: string) => ['seedSearch', name, crop],
};
