import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import turfBbox from '@turf/bbox';
import { Expression, FillPaint, LngLatBoundsLike, Map } from 'mapbox-gl';

import { FIELD_BOUNDARY, FIELD_OUTLINE } from 'constants/mapbox';
import { PROJECT_MAP_COLORS } from 'constants/projects';
import { projectsRoutes } from 'constants/routes';
import { routeParams } from 'constants/routes/projectsRoutes';

import { projectsQueryKeys } from 'util/queryKeys';
import { useTranslations } from 'strings/translation';
import { FieldFeature, FieldPropertiesType } from 'store/fields/types';
import { editProject } from 'store/projects/requests';
import {
  EditProjectMutationContext,
  ProjectMutationPayload,
  ProjectType,
} from 'store/projects/types';
import { MapboxClickEvent } from 'common/Maps/types';

import { useFieldsQueries } from './queries';

const idKey: keyof FieldPropertiesType = 'id';

export const useProjectsTranslations = () => {
  return useTranslations([
    'account',
    'accountName',
    'accounts',
    'acres',
    'actions',
    'add',
    'addAccount',
    'addGate',
    'additionalLab',
    'allAccounts',
    'allProjects',
    'analytics',
    'assignToCollection',
    'billingAgency',
    'cancel',
    'carbonPlanConfirmBody',
    'carbonPlanConfirmTitle',
    'choose',
    'chooseRegistry',
    'click',
    'clickFieldsToAssignColln',
    'clickToUndo',
    'collection',
    'collectionCreated',
    'collectionName',
    'collections',
    'confirm',
    'createCollection',
    'created',
    'createNewGate',
    'createNewProject',
    'createSamplingPlan',
    'dateAdded',
    'defaultLab',
    'deleteGate',
    'deleteSample',
    'deleteSampleWarning',
    'density',
    'done',
    'download',
    'download811msg',
    'downloadSoilCollectorExportMsg',
    'drawPolygon',
    'drawRectangle',
    'editProject',
    'editProjectName',
    'enableMeterCoreDetails',
    'existingCollections',
    'featureType',
    'field',
    'fields',
    'fieldsAddedToCollection',
    'fieldsLower',
    'gate',
    'gateCreatedMsg',
    'gates',
    'generateSamplingPlan',
    'hasGates',
    'hide',
    'lab',
    'labs',
    'lastEdited',
    'legend',
    'latitude',
    'longitude',
    'manageFeatures',
    'manageGates',
    'measurementTypes',
    'meterCoreSampleDetails',
    'minimumMeterCoreSamples',
    'moveSample',
    'myAccounts',
    'orderSamplingPlan',
    'otherFields',
    'per',
    'processingOrder',
    'project',
    'projectName',
    'projects',
    'projectUpdatedMsg',
    'registry',
    'removed',
    'removeFieldsFromProject',
    'removeSelectedFields',
    'reset',
    'sample',
    'sampleDeletedMsg',
    'sampleMustBeOnField',
    'samples',
    'sampleUpdated',
    'samplingPlan',
    'samplingPlanCreated',
    'samplingPlanErrors',
    'samplingPlanInProgress',
    'save',
    'search',
    'searchByUserAgency',
    'selectAccount',
    'selectAllOrNone',
    'selected',
    'selectedField',
    'selectedForAssignment',
    'showMore',
    'soilCollectorOutput',
    'splitCore',
    'splits',
    'status',
    'stratification',
    'stratify',
    'submit',
    'success',
    'texture',
    'textureAndPh',
    'texturePhSampleDetails',
    'thisMayTakeSeconds',
    'toCollection',
    'toggleFieldSelection',
    'uploadFile',
    'uploadFileInstrs',
    'viewField',
    'viewProject',
    'viewSamples',
    'with',
    'year',
    'yes',
  ]);
};

/** Converts IDs to numbers */
export const useProjectRouteParams = (): Record<
  keyof Pick<typeof routeParams, 'fieldId' | 'samplingPlanId' | 'projectId'>,
  number
> => {
  type RouteParams = Record<keyof typeof projectsRoutes.routeParams, string>;

  const { projectId = '', samplingPlanId = '', fieldId = '' } = useParams<RouteParams>();

  return {
    projectId: Number(projectId),
    samplingPlanId: Number(samplingPlanId),
    fieldId: Number(fieldId),
  };
};

export const useEditProjectMutation = (onSuccess?: (project: ProjectType) => void) => {
  const queryClient = useQueryClient();

  return useMutation<ProjectType, Error, ProjectMutationPayload, EditProjectMutationContext>({
    onSettled: () => queryClient.invalidateQueries({ queryKey: projectsQueryKeys.listProjects }),
    onSuccess,
    onMutate: async ({ fieldIds, projectId }) => {
      if (!fieldIds) return;

      const queryKey = projectsQueryKeys.editProject(projectId);
      const previousProjectState: ProjectType | undefined = queryClient.getQueryData(queryKey);

      queryClient.setQueryData<ProjectType>(queryKey, (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            fields: oldData.fields.filter((field) => fieldIds.includes(field.id)),
          };
        }
      });

      return { previousFieldIds: previousProjectState?.fields.map((field) => field.id) || [] };
    },
    mutationFn: ({ projectId, fieldIds, projectName }) => {
      return editProject(projectId, {
        ...(fieldIds ? { field_ids: fieldIds } : {}),
        name: projectName,
      });
    },
  });
};

export const useSelectedOperationsAndFieldsText = (
  selectedOperationCount: number,
  selectedFieldsCount: number,
) => {
  const translations = useProjectsTranslations();

  if (!selectedOperationCount) {
    return null;
  }

  const pluralizeOperations = selectedOperationCount > 1;
  const pluralizeFields = selectedFieldsCount > 1;

  return [
    selectedOperationCount,
    translations[pluralizeOperations ? 'accounts' : 'account'],
    translations.with,
    selectedFieldsCount,
    translations[pluralizeFields ? 'fields' : 'field'],
    translations.selected,
  ].join(' ');
};

export const useProjectFieldsLayer = (settings: {
  mapRef: React.MutableRefObject<mapboxgl.Map | null>;
  fieldIds: number[];
  mapHasLoaded: boolean;
  onClick?: (evt: MapboxClickEvent) => void;
  /** Omitting this will cause the map to zoom to all fields, not just one */
  currentFieldId?: number;
  fillPaint?: FillPaint;
}): { currentFieldFeature: FieldFeature | undefined } => {
  const {
    mapRef,
    fieldIds,
    mapHasLoaded,
    currentFieldId,
    onClick,
    fillPaint = {
      'fill-opacity': 0,
    },
  } = settings;

  const fieldsQueries = useFieldsQueries(fieldIds);

  const currentFieldFeature = fieldsQueries.fieldFeatures.find(
    (feature) => feature?.properties.id === currentFieldId,
  );

  useEffect(() => {
    const map = mapRef.current;

    if (fieldsQueries.isPending || !mapHasLoaded || !map) {
      return;
    }

    const featureCollection: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: fieldsQueries.fieldFeatures as GeoJSON.Feature[],
    };

    const bbox = turfBbox(currentFieldFeature || featureCollection);
    const currentFieldExpression: Expression = ['==', ['get', idKey], currentFieldId];

    if (!map.getSource(FIELD_BOUNDARY)) {
      map
        .addSource(FIELD_BOUNDARY, {
          type: 'geojson',
          promoteId: idKey,
          data: featureCollection,
        })
        // TODO: point labels
        .addLayer({
          id: FIELD_OUTLINE,
          type: 'line',
          source: FIELD_BOUNDARY,
        })
        .addLayer({
          id: FIELD_BOUNDARY,
          source: FIELD_BOUNDARY,
          type: 'fill',
          paint: fillPaint,
        });
    }

    // Manually set dynamic paint properties because `currentFieldId` changed, but we can't set it
    // in the `addLayer` since we jump ship if the source already exists.
    map
      .setPaintProperty(FIELD_OUTLINE, 'line-width', ['case', currentFieldExpression, 3, 2])
      .setPaintProperty(FIELD_OUTLINE, 'line-color', [
        'case',
        currentFieldExpression,
        PROJECT_MAP_COLORS.selectedField,
        PROJECT_MAP_COLORS.otherFields,
      ]);

    onClick && map.on('click', FIELD_BOUNDARY, onClick);

    if (!bbox.includes(Infinity)) {
      map.fitBounds(bbox as LngLatBoundsLike, {
        duration: 0,
        padding: 40,
      });
    }
  }, [fieldsQueries.isPending, mapHasLoaded, currentFieldFeature, currentFieldId]);

  return { currentFieldFeature };
};

export const useFieldHoverHandlers = () => {
  const handleFieldMouseEnter = useCallback((evt: MapboxClickEvent) => {
    evt.target.getCanvas().style.cursor = 'pointer';
  }, []);

  const handleFieldMouseLeave = useCallback((evt: MapboxClickEvent) => {
    evt.target.getCanvas().style.cursor = '';
  }, []);

  const setFieldMapCursorListeners = (map: Map) => {
    map.on('mouseenter', FIELD_BOUNDARY, handleFieldMouseEnter);
    map.on('mouseleave', FIELD_BOUNDARY, handleFieldMouseLeave);
  };

  const removeFieldMapCursorListeners = (map: Map) => {
    map.off('mouseenter', FIELD_BOUNDARY, handleFieldMouseEnter);
    map.off('mouseleave', FIELD_BOUNDARY, handleFieldMouseLeave);
  };

  return { setFieldMapCursorListeners, removeFieldMapCursorListeners };
};
