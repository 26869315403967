export const segments = {
  list: 'projects',
  createSamplingPlan: 'create-sampling-plan',
  samplingPlans: 'sampling-plans',
  field: 'field',
  fieldBoundary: 'field-boundary',
  fieldGates: 'gates',
  carbonCollections: 'carbon-collections',
};

export const routeParams = {
  projectId: 'projectId',
  samplingPlanId: 'samplingPlanId',
  fieldId: 'fieldId',
  operationId: 'operationId',
};

export const queryParams = {
  operations: 'operations',
  project: 'project',
  field: 'field',
  projectOperations: 'project_operations',
  tmpPlanId: 'plan_id',
};

const baseRoutes = {
  base: `/${segments.list}`,
  operationsList: '/operations/manage/all',
  fieldBoundary: `/${segments.fieldBoundary}`,
};

export const routes = {
  ...baseRoutes,
  samples: (projectId: string | number, samplingPlanId: string | number) => {
    return [baseRoutes.base, projectId, segments.samplingPlans, samplingPlanId].join('/');
  },
  fieldBoundary: (operationId: string | number, fieldId: string | number) => {
    return `${baseRoutes.fieldBoundary}/${operationId}/${fieldId}`;
  },
  project: (id: string | number) => {
    return `${baseRoutes.base}/${id}`;
  },
  createSamplePlanForProject: (projectId: string | number) => {
    return `${routes.project(projectId)}/${segments.createSamplingPlan}`;
  },
  samplingPlan: (projectId: string | number, samplingPlanId: string | number) => {
    return `${routes.project(projectId)}/${segments.samplingPlans}/${samplingPlanId}`;
  },
  samplingPlanField: (
    projectId: string | number,
    samplingPlanId: string | number,
    fieldId: string | number,
  ) => {
    return `${routes.samplingPlan(projectId, samplingPlanId)}/${fieldId}`;
  },
  field: (projectId: string | number, operationId: string | number, fieldId: string | number) => {
    return `${routes.project(projectId)}/${segments.field}/${operationId}/${fieldId}`;
  },
  samplingPlanCollections: (projectId: string | number, samplingPlanId: string | number) => {
    return `${routes.samplingPlan(projectId, samplingPlanId)}/${segments.carbonCollections}`;
  },
  fieldGates: (projectId: string | number, fieldId: string | number) => {
    return [routes.project(projectId), segments.fieldGates, fieldId].join('/');
  },
};
