import { UseMutationResult } from '@tanstack/react-query';

import { CAR_STANDARD, METER_CORE, REGEN_STANDARD, SOIL_TEXTURE } from 'constants/projects';

import { OperationFieldType } from 'store/operation/types';

type AcreageUnit = 'ac' | 'ha';

export type ProjectField = {
  id: number;
  created_at: string;
  updated_at: string | null;
  name: string;
  operation_id: number;
  operation_name: string;
  acreage: number;
  acreage_unit: AcreageUnit;
  has_gates: boolean;
  coordinates: {
    lat: number | null;
    lng: number | null;
  };
};

export type ProjectType = {
  id: number;
  name: string;
  created_by_id: number;
  created_at: string;
  /** Pretty date, e.g. "Feb 13, 2025, 3:10:56 PM" */
  created_at_display: string;
  updated_at: string | null;
  fields: ProjectField[];
  total_acreage: number;
};

export type CreateProjectPayloadType = {
  field_ids: number[];
  project: {
    name: string;
  };
};

export type EditProjectPayloadType = {
  field_ids: number[];
  name: string;
};

export type GetProjectsPayloadType = {
  page?: number;
  name?: string;
};

/** Passed to mutation function prior to API call */
export type ProjectMutationPayload = {
  projectId: number;
  projectName?: string;
  fieldIds?: number[];
};

/** Not "react" context, but the third arg of the mutation's `onSuccess` */
export type EditProjectMutationContext = {
  /** Used in the 'Undo' toast in case user wants to restore previous values */
  previousFieldIds: number[];
};

export type EditMutation = UseMutationResult<
  ProjectType,
  Error,
  Partial<ProjectMutationPayload>,
  EditProjectMutationContext
>;

export type ProjectFieldsUseQueriesResult = {
  isPending: boolean;
  fields: Array<
    OperationFieldType & {
      operationName: string;
      operationId: number;
    }
  >;
};

export type SamplePlanRegistryType = typeof REGEN_STANDARD | typeof CAR_STANDARD;

export type TextureDensity =
  | '1 per field'
  | '1 per 3 fields'
  | '1 per 5 fields'
  | '1 per stratum'
  | '3 per stratum';

type MeasurementType = typeof METER_CORE | typeof SOIL_TEXTURE;

type MeasurementTypeInfo = {
  sample_type_name: string;
  lab_name: string;
  test_names: string[];
  action_name: string;
};

export type DefaultSamplingPlanConfigResponse = {
  [key in SamplePlanRegistryType]: {
    min_samples_per_strata: number;
    measurement_type_info: {
      [measurementTypeKey in Extract<MeasurementType, 'Meter Core'>]: MeasurementTypeInfo;
    } & Partial<{
      [measurementTypeKey in Extract<MeasurementType, 'Soil Texture'>]: MeasurementTypeInfo;
    }>;
  };
};

export type CreateSamplingPlanMutationData = {
  fieldIds: number[];
} & Pick<SamplePlanForm, 'density' | 'registry'>;

export type CreateSamplingPlanPayloadType = {
  field_ids: number[];
  sample_plan: {
    registry: SamplePlanRegistryType;
    project_id: number;
    /** Starts life as a string because Mantine */
    density: number;
  };
};

export type SamplePlanForm = {
  registry: SamplePlanRegistryType;
  defaultLab: string;
  hasMeterCoreDetails: boolean;
  /** Convert to number before POST */
  density: string;
  isSplitCore: boolean;
  splits: Array<string>;
  analytics: Array<string>;
  lab: string;
  hasTexture: boolean;
  stratify: boolean;
  minMeterCoreSamplesPerStratum: number;
  /** No clue what payload will be yet */
  textureDensity: TextureDensity | null;
};

export type FieldSample = {
  id: number;
  field: ProjectField;
  collection_uuid: string;
  sample_points_geom: GeoJSON.Point;
  sample_points_geom_text: string;
  field_id: number;
  field_geometry_id: number;
  updated_at: string | null;
  created_at: string;
};

type CarbonCollectionField = {
  id: number;
  collection_uuid: string;
  field_id: number;
  field_geometry_id: number;
  updated_at: null | string;
  created_at: string;
  field: Omit<ProjectField, 'updated_at' | 'created_at'>;
  sample_count: number;
};

export type CarbonCollection = {
  id: number;
  blob_name: string | null;
  bucket_name: string | null;
  carbon_sample_plan_id: number;
  carbon_sample_plans: number;
  collection_uuid: string;
  created_at: string;
  field_samples: FieldSample[] | undefined; // TODO: why undefined in response?
  name: string;
  updated_at: string | null;
  collection_fields: CarbonCollectionField[];
};

export type ProjectSamplingCountResponse = {
  running_jobs: number;
};

export type PlanConfig = {
  min_samples_per_strata: number;
  measurement_type_info: {
    [key: string]: MeasurementTypeInfo;
  };
};

export enum SimpleQueueStatus {
  INCOMPLETE_NOT_READY = 2,
  INCOMPLETE_READY = 0,
  PROCESSED = 1,
  FAILED = -1,
}

export enum SimpleQueueStatusString {
  INCOMPLETE_NOT_READY = '2',
  INCOMPLETE_READY = '0',
  PROCESSED = '1',
  FAILED = '-1',
}

export type CarbonSamplingPlanType = {
  carbon_collections: CarbonCollection[];
  project: number;
  complete: SimpleQueueStatus;
  primary: boolean;
  errors: null | string;
  id: number;
  project_id: number;
  created_by_id: number;
  output_files_metadata: null | unknown;
  registry: string;
  plan_config: PlanConfig;
  density: number;
  updated_at: string | null;
  created_at: string;
};

export type CarbonSampleProperties = {
  id: number;
  carbon_sample_plan_id: number;
  field_id: number;
  strata_id: number;
  active: boolean;
  updated_at: string | null;
  feature_type: string;
  measurement_types: string;
  created_at: string;
};

export type CarbonSampleFeature = GeoJSON.Feature<GeoJSON.Polygon, CarbonSampleProperties>;

export type CarbonProjectFieldRowData = ProjectField & {
  /** Used for linking to sampling plan view. Undefined until a plan is generated. */
  samplingPlanId?: number;
  created_at: string;
  updated_at: string | null;
};

export type CarbonSamplePutPayload = GeoJSON.Feature<GeoJSON.Point>;

export type CarbonSampleMutationData = {
  id: number;
  feature: GeoJSON.Feature<GeoJSON.Point>;
};

export type CreateCarbonCollectionPayload = Pick<
  CarbonCollection,
  'name' | 'carbon_sample_plan_id'
>;

export type FieldGateProperties = {
  id: number;
  field_id: number;
  nearest_road: string | null;
  intersecting_road: string | null;
  created_at: string;
  updated_at: string;
};

export type FieldGate = GeoJSON.Feature<GeoJSON.Point, FieldGateProperties>;

export type CreateFieldGatePayload = GeoJSON.Feature<
  GeoJSON.Point,
  Pick<FieldGateProperties, 'field_id'> &
    Partial<Pick<FieldGateProperties, 'nearest_road' | 'intersecting_road'>>
>;

export type CarbonProgressMessage = {
  timestamp: string;
  message: string;
};

export type CarbonSamplingProgress = {
  status: SimpleQueueStatusString;
  messages: CarbonProgressMessage[];
};
