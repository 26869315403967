import { useEffect, useState } from 'react';
import { Button, Group } from '@mantine/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { LngLat, Marker } from 'mapbox-gl';

import { ReactComponent as DefaultMapboxMarker } from 'images/icons/mapMarkers/defaultMapboxMarker.svg';

import { SELECTED_MARKER_RED } from 'constants/colors';
import { MAP_GUTTERS } from 'constants/mapbox';
import { projectsRoutes } from 'constants/routes';

import useReactMapbox from 'util/hooks/useReactMapbox';
import { projectsQueryKeys } from 'util/queryKeys';
import showToast from 'actions/toastActions';
import { FieldPropertiesType } from 'store/fields/types';
import { moveCarbonSample } from 'store/projects/requests';
import {
  CarbonSampleFeature,
  CarbonSampleMutationData,
  CarbonSampleProperties,
} from 'store/projects/types';
import Popup from 'common/Maps/Popup';
import { MapboxClickEvent, PopupState } from 'common/Maps/types';

import BasicPopupContent from '../BasicPopupContent';
import {
  useFieldHoverHandlers,
  useProjectFieldsLayer,
  useProjectRouteParams,
  useProjectsTranslations,
} from '../hooks';
import ProjectMapLegend from '../ProjectMapLegend';

import DeleteSamplePointModal from './DeleteSamplePointModal';
import SamplePointPopupContent from './SamplePointPopupContent';

type Props = {
  projectFieldIds: number[];
  projectQueryIsLoading: boolean;
  sampleCentroidsFeatures: GeoJSON.Feature<GeoJSON.Point, CarbonSampleProperties>[];
};

const CarbonSamplingPlanMap = ({
  sampleCentroidsFeatures,
  projectFieldIds,
  projectQueryIsLoading,
}: Props) => {
  const { fieldId, projectId, samplingPlanId } = useProjectRouteParams();
  const queryClient = useQueryClient();
  const translations = useProjectsTranslations();
  const { setFieldMapCursorListeners, removeFieldMapCursorListeners } = useFieldHoverHandlers();

  const [idOfSampleToDelete, setIdOfSampleToDelete] = useState<number>();
  const [idOfSampleToMove, setIdOfSampleToMove] = useState<number>();
  const [popupInfo, setPopupInfo] = useState<PopupState>(null);
  const [latLonOfMovedSample, setLatLonOfMovedSample] = useState<LngLat>();

  const closePopup = () => setPopupInfo(null);

  const [sampleMarkers, setSampleMarkers] = useState<
    Array<{
      marker: Marker;
      sampleId: number;
    }>
  >([]);

  const moveMutation = useMutation<CarbonSampleFeature, Error, CarbonSampleMutationData>({
    mutationFn: (payload) => moveCarbonSample(payload.id, payload.feature),
  });

  const handleMoveSubmit = () => {
    if (!idOfSampleToMove || !latLonOfMovedSample) return;

    moveMutation.mutate(
      {
        id: idOfSampleToMove,
        feature: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [latLonOfMovedSample.lng, latLonOfMovedSample.lat],
          },
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: projectsQueryKeys.samples(samplingPlanId),
          });

          showToast(translations.sampleUpdated, 'success');
        },
        onSettled: () => {
          !!mapRef.current && removeFieldMapCursorListeners(mapRef.current);
          setIdOfSampleToMove(undefined);
        },
      },
    );
  };

  const handleMoveSampleBtnClick = (sampleId: number) => {
    closePopup();
    setIdOfSampleToMove(sampleId);
  };

  const handleMarkerClick = (
    evt: MouseEvent,
    properties: CarbonSampleProperties,
    [lng, lat]: [number, number],
  ) => {
    evt.stopPropagation();
    evt.preventDefault();

    setPopupInfo({
      lng,
      lat,
      content: (
        <SamplePointPopupContent
          onDeleteClick={() => setIdOfSampleToDelete(properties.id)}
          properties={properties}
          onMoveSampleClick={() => handleMoveSampleBtnClick(properties.id)}
        />
      ),
    });
  };

  const { mapContainerRef, mapRef, mapHasLoaded } = useReactMapbox({
    onLoad: setFieldMapCursorListeners,
  });

  const handleFieldClick = (evt: MapboxClickEvent) => {
    const clickedFeature = evt.features?.length ? evt.features[0] : null;
    if (!clickedFeature) return;

    const properties = clickedFeature.properties as FieldPropertiesType;

    setPopupInfo({
      ...evt.lngLat,
      content: (
        <BasicPopupContent
          onClick={closePopup}
          titleText={properties.name}
          buttonText={translations.viewSamples}
          routeTo={projectsRoutes.routes.samplingPlanField(
            projectId,
            samplingPlanId,
            properties.id,
          )}
        />
      ),
    });
  };

  const { currentFieldFeature } = useProjectFieldsLayer({
    mapHasLoaded: mapHasLoaded && !projectQueryIsLoading,
    mapRef,
    fieldIds: projectFieldIds,
    currentFieldId: fieldId,
    onClick: handleFieldClick,
  });

  const handleDragEnd = (marker: Marker, originalLngLat: [number, number]) => {
    const newLngLat = marker.getLngLat();

    const isInsideField =
      currentFieldFeature &&
      booleanPointInPolygon([newLngLat.lng, newLngLat.lat], currentFieldFeature.geometry);

    if (isInsideField) {
      setLatLonOfMovedSample(newLngLat);
    } else {
      showToast(translations.sampleMustBeOnField, 'error');
      marker.setLngLat(originalLngLat);
    }
  };

  useEffect(() => {
    const map = mapRef.current;
    if (!map || !mapHasLoaded) return;

    sampleMarkers.forEach(({ marker }) => {
      marker.remove();
    });

    const markers = sampleCentroidsFeatures.map(({ geometry, properties }) => {
      const isSelected = idOfSampleToMove === properties.id;

      const marker = new Marker({
        draggable: isSelected,
        color: isSelected ? SELECTED_MARKER_RED : undefined,
      });

      const lngLat = geometry.coordinates as [number, number];
      const element = marker.getElement();

      element.addEventListener('click', (evt) => {
        handleMarkerClick(evt, properties, lngLat);
      });

      element.style.cursor = 'pointer';

      if (isSelected) {
        marker.on('dragend', () => {
          handleDragEnd(marker, lngLat);
        });
      }

      marker.setLngLat(lngLat).addTo(map);

      return { marker, sampleId: properties.id };
    });

    setSampleMarkers(markers);
  }, [idOfSampleToMove, sampleCentroidsFeatures, mapHasLoaded]);

  return (
    <Group w="100%" align="start" flex={1} gap="xl" style={{ overflow: 'hidden' }} pos="relative">
      {!!idOfSampleToMove && (
        <Group
          top={MAP_GUTTERS.default}
          left={MAP_GUTTERS.default}
          pos="absolute"
          style={{ zIndex: 3 }}
        >
          <Button
            onClick={() => {
              setIdOfSampleToMove(undefined);
              !!mapRef.current && setFieldMapCursorListeners(mapRef.current);
            }}
          >
            {translations.cancel}
          </Button>
          <Button onClick={handleMoveSubmit}>{translations.done}</Button>
        </Group>
      )}
      <DeleteSamplePointModal
        sampleId={idOfSampleToDelete}
        samplingPlanId={samplingPlanId}
        onClose={() => setIdOfSampleToDelete(undefined)}
        onSettled={() => {
          closePopup();
          setIdOfSampleToDelete(undefined);
        }}
      />
      <ProjectMapLegend
        additionalItems={[
          {
            label: translations.samples,
            icon: (
              <Group justify="center" align="center" h={24}>
                <DefaultMapboxMarker />
              </Group>
            ),
          },
        ]}
      />
      <div ref={mapContainerRef} style={{ height: '100%', width: '100%', position: 'absolute' }}>
        {popupInfo && mapRef.current && (
          <Popup {...popupInfo} map={mapRef.current} anchor="bottom" onClose={closePopup}>
            {popupInfo.content}
          </Popup>
        )}
      </div>
    </Group>
  );
};

export default CarbonSamplingPlanMap;
