import { useEffect, useState } from 'react';
import { FaCheck, FaExclamation } from 'react-icons/fa';
import { Group, List, Loader, Paper, Spoiler, Stack, Text, ThemeIcon } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';

import { UI_COLORS } from 'constants/colors';

import { projectsQueryKeys } from 'util/queryKeys';
import { getSamplingPlanProgressLog } from 'store/projects/requests';
import { SimpleQueueStatusString as PlanStatus } from 'store/projects/types';

import { useProjectsTranslations } from '../hooks';

type Props = {
  samplingPlanId: null | string;
  // TODO: restore when the plan id is available via not the url
  // samplingPlanStatus?: CarbonSamplingPlanType['complete'];
};

const FIVE_SECONDS = 5 * 1000;

const CarbonPlanProgressLog = ({ samplingPlanId }: Props) => {
  const translations = useProjectsTranslations();
  // TODO: restore when the plan id is available via not the url
  // const [queryIsActive, setQueryIsActive] = useState(
  //   !!samplingPlanId && samplingPlanStatus === PlanStatus.INCOMPLETE_READY,
  // );
  const [queryIsActive, setQueryIsActive] = useState(!!samplingPlanId);
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>(FIVE_SECONDS);

  const samplingPlanLogsQuery = useQuery({
    enabled: queryIsActive,
    queryKey: projectsQueryKeys.samplingPlanProgressLog(samplingPlanId as string),
    queryFn: () => getSamplingPlanProgressLog(samplingPlanId as string),
    refetchInterval,
  });

  const messages = samplingPlanLogsQuery.data?.messages || [];
  const status = samplingPlanLogsQuery.data?.status;

  useEffect(() => {
    if (status === PlanStatus.PROCESSED) {
      setQueryIsActive(false);
    } else if (status === PlanStatus.FAILED) {
      setRefetchInterval(undefined);
    }
  }, [status]);

  if (samplingPlanLogsQuery.isPending || !queryIsActive) {
    return null;
  }

  const reversedMessages = messages.length
    ? [...messages].reverse()
    : [
        {
          // API does not provide a `Z` suffix for the timestamp, so that's the assumption in the
          // render loop below, so need to rm it manually here.
          timestamp: new Date().toISOString().slice(0, -1),
          message: translations.processingOrder,
        },
      ];

  const getIcon = (isFirst: boolean, message: string) => {
    if (message.toLowerCase().includes('error')) {
      return (
        <ThemeIcon radius="xl" color={UI_COLORS.error} size={24}>
          <FaExclamation />
        </ThemeIcon>
      );
    }

    return isFirst && status === PlanStatus.INCOMPLETE_READY ? <Loader size={16} /> : undefined;
  };

  return (
    <Paper shadow="sm" pt="xl" pb="xl" px="lg" mb="lg" radius="md" withBorder maw={600} mx="auto">
      <Group justify="center">
        <Spoiler
          maxHeight={130}
          showLabel={
            <Group display="inline-flex" mt="md" mx="auto">
              {translations.showMore}
            </Group>
          }
          hideLabel={
            <Group display="inline-flex" mt="md" mx="auto">
              {translations.hide}
            </Group>
          }
          styles={{
            control: {
              display: 'flex',
              fontWeight: 700,
              bottom: 0,
              right: 0,
            },
          }}
        >
          <List
            spacing="xs"
            size="sm"
            center
            icon={
              <ThemeIcon radius="xl" color={UI_COLORS.success} size={24}>
                <FaCheck />
              </ThemeIcon>
            }
          >
            {reversedMessages.map((log, i) => (
              <List.Item key={log.timestamp} icon={getIcon(i === 0, log.message)}>
                <Stack gap={0}>
                  <Text size="sm" fw="bold">
                    {log.message}
                  </Text>
                  <Text size="xs" c="dimmed">
                    {new Date(`${log.timestamp}Z`).toLocaleString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </Text>
                </Stack>
              </List.Item>
            ))}
          </List>
        </Spoiler>
      </Group>
    </Paper>
  );
};

export default CarbonPlanProgressLog;
