import { useEffect, useState } from 'react';
import { Box, ComboboxItem, Group, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { getProNutrientPopupSettings } from 'apps/Results/common/MapPopup';

import { COLORS, MAP_HEADER_GRAY } from 'constants/colors';
import { NDVI_KEY } from 'constants/imagery';
import { IMAGERY, SOIL_TEST, YIELD } from 'constants/prescription';

import { getRiskColorFill } from 'util/chartUtils';
import { toCamelCase } from 'util/formatKeys';
import useReactMapbox from 'util/hooks/useReactMapbox';
import { addImageryDataToMap, addMachineDataToMap } from 'util/prescription';
import { getNonTillageProMapPaintFill, setNonTillageProHoverHandlers } from 'util/proMaps';
import {
  addFieldAndGetZoom,
  addSampleLayersToMap,
  getAnalyticFromPlan,
  getFieldRisk,
  setupNonTillageProLayer,
} from 'util/results';
import { getUnitBuAc } from 'util/units';
import { getString } from 'strings/translation';
import { EOInferenceLayerType } from 'store/eoCollections/types';
import { FieldType } from 'store/fields/types';
import { SampleFeatureType } from 'store/samples/types';
import Popup from 'common/Maps/Popup';
import { PopupState } from 'common/Maps/types';

import { PrescriptionStateType } from './Container';
import MissingDataMessage from './MissingDataMessage';

import styles from './Map.module.css';

type ScriptCreatorMapProps = {
  field: FieldType;
  handleGenerateFromChange: (val: string) => void;
  harvestYearOptions: ComboboxItem[];
  imageryYearOptions: ComboboxItem[];
  isRemoval: boolean;
  language: string;
  prescriptionForm: UseFormReturnType<PrescriptionStateType>;
  proLayer: EOInferenceLayerType | null | undefined;
  samples?: SampleFeatureType[];
};

const ScriptCreatorMap = ({
  field,
  harvestYearOptions,
  handleGenerateFromChange,
  imageryYearOptions,
  isRemoval,
  language,
  prescriptionForm,
  proLayer,
  samples,
}: ScriptCreatorMapProps) => {
  const { analytic, customMapLayer, generateFrom, harvestLayer, imageryLayer, samplePlan } =
    prescriptionForm.getValues();
  const {
    composite_imagery_layers,
    harvest_data_files,
    nutrient_results_available,
    sampling_plans,
  } = field.features[0].properties;

  const [popupInfo, setPopupInfo] = useState<PopupState>(null);

  const { mapContainerRef, mapRef, mapHasLoaded } = useReactMapbox();

  const selectedSamplingPlan = sampling_plans.find((plan) => plan.id === Number(samplePlan));
  const shouldShowProMap = proLayer && !!selectedSamplingPlan?.pro_densities.length;
  const planAnalytic = analytic ? getAnalyticFromPlan(selectedSamplingPlan, analytic) : null;

  const risk =
    selectedSamplingPlan && analytic && planAnalytic
      ? getFieldRisk(planAnalytic, selectedSamplingPlan, analytic)
      : '';
  const harvestData = isRemoval
    ? harvest_data_files.find((file) => file.processed_geojson_uri === harvestLayer)
    : null;
  const imageryData = isRemoval
    ? composite_imagery_layers.find((file) => file.geojson_uri === imageryLayer)
    : null;

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      if (!map || !mapHasLoaded || !field) {
        return;
      }
      addFieldAndGetZoom(map, field, 30);
    }
  }, [field, mapHasLoaded, mapRef]);

  const isYieldMap = [generateFrom, customMapLayer].includes(YIELD);
  const isImageryMap = [generateFrom, customMapLayer].includes(IMAGERY);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const prefix = 'layer';
      const style = map.getStyle();
      style.layers.forEach((layer) => {
        if (layer.id.startsWith(prefix)) {
          map.removeLayer(layer.id);
        }
      });
      Object.keys(style.sources).forEach((source) => {
        if (source.startsWith(prefix)) {
          map.removeSource(source);
        }
      });
      if (harvestData && isYieldMap) {
        const sourceLayerId = `${prefix}-${harvestData.id}`;
        addMachineDataToMap(harvestData, map, sourceLayerId);
      } else if (imageryData && isImageryMap) {
        const sourceLayerId = `${prefix}-${imageryData.id}`;
        addImageryDataToMap(imageryData, map, sourceLayerId);
      } else if (shouldShowProMap && planAnalytic?.data_summary && analytic) {
        const sourceLayerId = `${prefix}-${analytic.id}`;

        const fillColor = getNonTillageProMapPaintFill(
          analytic,
          planAnalytic.data_summary,
          proLayer,
        );
        const popupSettings = getProNutrientPopupSettings(analytic.id, proLayer, planAnalytic.unit);
        setNonTillageProHoverHandlers(map, sourceLayerId);
        setupNonTillageProLayer(
          map,
          sourceLayerId,
          fillColor,
          proLayer.geojson_uri,
          (info) => setPopupInfo(info),
          popupSettings,
        );
      } else if (samples && analytic && selectedSamplingPlan) {
        const samplesId = `${prefix}-samples`;
        const samplesQuantityId = `${prefix}-samples-quantities`;
        const validSamples = samples.filter(
          (sample) =>
            sample.geometry &&
            Object.keys(sample.properties.analytics[analytic.category]?.[analytic.id] || {}).length,
        );

        // select only 1 geometry to avoid map duplication. Prioritize multipoly then polygon
        const samplesWithPrimaryGeometry = validSamples
          .map((val) => ({
            ...val,
            geometry:
              val.geometry.geometries.find((geom) => geom.type === 'MultiPolygon') ||
              val.geometry.geometries.find((geom) => geom.type === 'Polygon') ||
              null,
          }))
          .filter(({ geometry }) => geometry);
        addSampleLayersToMap(
          analytic,
          selectedSamplingPlan,
          map,
          field,
          // Despite the helper requiring a mapbox sample and the explicit non-null filtering above,
          // TS doesn't recognize that the Geometry property of the sample is not null
          // @ts-expect-error
          samplesWithPrimaryGeometry,
          {
            points: samplesId,
            pointsQuantity: samplesQuantityId,
          },
        );
      }
      map.getStyle();
    }
  }, [
    analytic,
    harvestData,
    imageryData,
    isImageryMap,
    isYieldMap,
    mapHasLoaded,
    mapRef,
    samples,
    selectedSamplingPlan,
    shouldShowProMap,
    planAnalytic,
    proLayer,
  ]);

  if (!(analytic || customMapLayer)) {
    return null;
  }

  const isYield = [generateFrom, customMapLayer].includes(YIELD);
  const isImagery = [generateFrom, customMapLayer].includes(IMAGERY);

  const getHeaderText = () => {
    if (harvestData && isYield) {
      return (
        <>
          {analytic && (
            <Text c={COLORS.white} fw={700}>
              {analytic.name}
            </Text>
          )}
          <Text c={COLORS.white}>
            <b>{getString('yield', language)}:</b>{' '}
            {`${Math.round(harvestData.mean || 0)} ${getUnitBuAc(field.features[0].properties.acreage_unit)}`}
          </Text>
        </>
      );
    }
    if (imageryData && isImagery) {
      return (
        <>
          {analytic && (
            <Text c={COLORS.white} fw={700}>
              {analytic.name}
            </Text>
          )}
          <Text c={COLORS.white}>
            <b>{getString(NDVI_KEY, language)}:</b> {`${(imageryData.mean || 0).toFixed(2)}`}
          </Text>
        </>
      );
    }
    return analytic ? (
      <>
        <Text fw={700}>{analytic.name}</Text>
        {risk && (
          <Text>
            <b>{getString('risk', language)}:</b> {getString(toCamelCase(risk), language)}
          </Text>
        )}
      </>
    ) : null;
  };

  const dataWarning = (() => {
    if (generateFrom === SOIL_TEST && !nutrient_results_available) {
      return (
        <MissingDataMessage
          field={field}
          handleGenerateFromChange={handleGenerateFromChange}
          language={language}
          type={SOIL_TEST}
        />
      );
    }

    if (isRemoval) {
      if (generateFrom === YIELD && !harvestYearOptions.length) {
        return (
          <MissingDataMessage
            field={field}
            handleGenerateFromChange={handleGenerateFromChange}
            language={language}
            type={YIELD}
          />
        );
      }
      if (generateFrom === IMAGERY && !imageryYearOptions.length) {
        return (
          <MissingDataMessage
            field={field}
            handleGenerateFromChange={handleGenerateFromChange}
            language={language}
            type={IMAGERY}
          />
        );
      }
      return null;
    }
  })();

  const getHeaderFillColor = () => {
    if (harvestData || imageryData || !planAnalytic) {
      return MAP_HEADER_GRAY;
    }
    return getRiskColorFill(planAnalytic.risk_level);
  };

  return (
    <Box className={styles.FieldMap}>
      {!dataWarning && (
        <Group bg={getHeaderFillColor()} gap="sm" pl="md">
          {getHeaderText()}
        </Group>
      )}
      {dataWarning}
      <Box display={dataWarning ? 'none' : 'block'} h="18rem" ref={mapContainerRef} w="100%">
        {popupInfo && mapRef.current && (
          <Popup
            {...popupInfo}
            map={mapRef.current}
            anchor="bottom"
            onClose={() => setPopupInfo(null)}
          >
            {popupInfo.content}
          </Popup>
        )}
      </Box>
    </Box>
  );
};

export default ScriptCreatorMap;
