import { ReactNode } from 'react';
import { Card } from '@mantine/core';

import { COLORS, UI_COLORS } from 'constants/colors';

type Props = {
  children: ReactNode;
  bgColor?: string;
  textColor?: string;
};

const DisplayBanner = ({
  children,
  bgColor = UI_COLORS.darkBlue,
  textColor = COLORS.white,
}: Props) => {
  return (
    <Card withBorder shadow="sm" radius="md" p="xs" bg={bgColor} c={textColor}>
      {children}
    </Card>
  );
};

export default DisplayBanner;
