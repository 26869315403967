import { useState } from 'react';
import { useDebouncedCallback } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { DataTable, useDataTableColumns } from 'mantine-datatable';

import { projectsQueryOptions } from 'util/queryOptions';
import { ProjectType } from 'store/projects/types';

import getColumns from './getProjectsColumns';
import { useProjectsTranslations } from './hooks';

const STORE_COLUMNS_KEY = 'projects-columns';

const ProjectsTable = () => {
  const translations = useProjectsTranslations();
  const [projectNameSearchText, setProjectNameSearchText] = useState('');
  const [page, setPage] = useState(1);
  const { data, isPending } = useQuery(projectsQueryOptions({ page, name: projectNameSearchText }));
  const [nameFilterIsLoading, setNameFilterIsLoading] = useState(false);

  const handleNameChange = useDebouncedCallback(async (query: string) => {
    setNameFilterIsLoading(true);
    setProjectNameSearchText(query);
    setNameFilterIsLoading(false);
  }, 250);

  const columns = getColumns({
    translations,
    projectNameSearchText,
    setProjectNameSearchText: handleNameChange,
    nameFilterIsLoading,
  });

  const { effectiveColumns } = useDataTableColumns<ProjectType>({
    key: STORE_COLUMNS_KEY,
    columns,
  });

  return (
    <DataTable
      highlightOnHover
      columns={effectiveColumns}
      fetching={isPending}
      page={page}
      records={data?.items || []}
      recordsPerPage={data?.per_page || 10}
      storeColumnsKey={STORE_COLUMNS_KEY}
      totalRecords={data?.total || 0}
      onPageChange={(newPage) => setPage(newPage)}
    />
  );
};

export default ProjectsTable;
