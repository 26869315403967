import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';

import { projectsRoutes } from 'constants/routes';

import { usePageTitle } from 'util/hooks/usePageTitle';
import { Header } from 'common';

import { useProjectsTranslations } from './hooks';
import ProjectsTable from './ProjectsTable';

const ProjectsContainer = () => {
  const translations = useProjectsTranslations();

  usePageTitle(translations.projects);

  return (
    <div>
      <Header title={translations.projects}>
        <Button
          component={Link}
          to={`${projectsRoutes.routes.operationsList}?${projectsRoutes.queryParams.project}`}
        >
          {translations.createNewProject}
        </Button>
      </Header>
      <ProjectsTable />
    </div>
  );
};

export default ProjectsContainer;
