import { Link } from 'react-router-dom';
import { Anchor, Button } from '@mantine/core';
import { DataTableColumn } from 'mantine-datatable';

import { projectsRoutes } from 'constants/routes';

import { convertUtcToLocale } from 'util/date';
import { ProjectType } from 'store/projects/types';

import { useProjectsTranslations } from './hooks';
import ProjectNameFilterInput from './ProjectNameFilterInput';

const getColumns = ({
  translations,
  projectNameSearchText,
  setProjectNameSearchText,
  nameFilterIsLoading,
}: {
  nameFilterIsLoading: boolean;
  projectNameSearchText: string;
  setProjectNameSearchText: (query: string) => void;
  translations: ReturnType<typeof useProjectsTranslations>;
}): DataTableColumn<ProjectType>[] => [
  {
    accessor: 'name',
    filtering: projectNameSearchText !== '',
    resizable: true,
    title: translations.projectName,
    width: 150,
    render: ({ name, id }) => (
      <Anchor fw="bold" component={Link} to={projectsRoutes.routes.project(id)}>
        {name}
      </Anchor>
    ),
    filter: (
      <ProjectNameFilterInput
        nameFilterIsLoading={nameFilterIsLoading}
        onChange={setProjectNameSearchText}
        translations={translations}
        initialValue={projectNameSearchText}
      />
    ),
  },
  {
    accessor: 'fields',
    width: 100,
    title: translations.fields,
    render: ({ fields }) => fields.length,
  },
  {
    accessor: 'total_acreage', // TODO: confirm hectares vs acres
    width: 100,
    title: translations.acres,
  },
  {
    accessor: 'created_at',
    width: 120,
    render: ({ created_at }) => convertUtcToLocale(created_at).getFullYear(),
    title: translations.year,
  },
  {
    accessor: 'actions',
    title: translations.actions,
    width: 100,
    render: (row) => (
      <Button
        component={Link}
        to={projectsRoutes.routes.project(row.id)}
        size="xs"
        variant="outline"
      >
        {translations.viewProject}
      </Button>
    ),
  },
];

export default getColumns;
