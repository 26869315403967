import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, SegmentedControl } from '@mantine/core';
import turfBbox from '@turf/bbox';
import center from '@turf/center';
import { featureCollection } from '@turf/helpers';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';

import { COLORS, PRESCRIPTION_COLORS, RISK_FILL_COLORS } from 'constants/colors';
import { NDVI_KEY } from 'constants/imagery';
import { DEFAULT_HARVEST_MAX, DEFAULT_HARVEST_MIN } from 'constants/machineData';
import { FIELD_OUTLINE, MAPBOX_FIT_PARAMS } from 'constants/mapbox';
import {
  AMOUNT_KEY,
  APPLICATION_RATE_MODE,
  IMAGERY_MAP_MODE,
  IMAGERY_ZONE_MODE,
  RAW_VALUE_KEY,
  YIELD_MAP_MODE,
  YIELD_ZONE_MODE,
} from 'constants/prescription';

import useBroswerLanguage from 'util/hooks/useLanguage';
import {
  addImageryDataToMap,
  addMachineDataToMap,
  addProPrescriptionToMap,
  formatZonesWithOpacity,
  getNdviMapSteps,
} from 'util/prescription';
import { capitalize } from 'util/stringUtils';
import { getUnitBuAc, getUnitLbsAc } from 'util/units';
import { getString } from 'strings/translation';
import { RootState } from 'store';
import { FieldType } from 'store/fields/types';
import { PrescriptionZoneType } from 'store/prescriptions/types';
import useMapboxGl from 'common/MapHooks';
import Popup from 'common/Maps/Popup';
import { PopupState, ViewPortProps } from 'common/Maps/types';

import { LegendWrap } from '../common/MapLegend/LegendWrap';
import {
  getNdviPopupContent,
  getPopupContent,
  getYieldPopupContent,
} from '../FieldSummary/PopupContent';

import MapScriptInfo from './MapScriptInfo';

import styles from './Map.module.css';

interface MapProps {
  field: FieldType;
  mapRef: { current: mapboxgl.Map | null };
  mapContainerRef: { current: HTMLDivElement | null };
  zones: PrescriptionZoneType[] | null;
  selectedZone: number | null;
}

type paramsType = {
  prescriptionId: string;
};

const Map = ({ field, mapRef, mapContainerRef, zones, selectedZone }: MapProps) => {
  const language = useBroswerLanguage();
  const params = useParams<paramsType>();
  const prescriptionId = Number(params.prescriptionId);

  const { proPrescriptions } = useSelector((state: RootState) => ({
    proPrescriptions:
      state.prescriptionZones.proPrescriptions[field.features[0].properties.id] || [],
  }));

  const [centerLongitude, centerLatitude] = center(field).geometry?.coordinates as number[];
  const [initialViewport, setInitialViewport] = useState({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
  });
  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: centerLatitude,
    longitude: centerLongitude,
    zoom: 5.5,
    width: 0,
    height: 0,
  });
  const [popupInfo, setPopupInfo] = useState<PopupState>(null);
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [mode, setMode] = useState(APPLICATION_RATE_MODE);

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, true);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.on('load', () => {
        setMapHasLoaded(true);
      });
    }
  }, [mapRef, setMapHasLoaded]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const bbox = turfBbox(field) as LngLatBoundsLike;

      const source = { type: 'geojson', data: field } as GeoJSONSourceRaw;

      if (map.getLayer(FIELD_OUTLINE)) {
        map.removeLayer(FIELD_OUTLINE);
      }
      if (map.getSource(FIELD_OUTLINE)) {
        map.removeSource(FIELD_OUTLINE);
      }

      map.addLayer({
        id: FIELD_OUTLINE,
        type: 'line',
        source,
        paint: { 'line-color': COLORS.white, 'line-width': 2 },
      });

      map.fitBounds(bbox, MAPBOX_FIT_PARAMS);
      const zoom = map.getZoom();
      setViewport((prevViewport) => ({
        ...prevViewport,
        centerLatitude,
        centerLongitude,
        zoom,
      }));
      setInitialViewport((prevViewport) => ({
        ...prevViewport,
        latitude: centerLatitude,
        longitude: centerLongitude,
        zoom,
      }));
    }
  }, [centerLatitude, centerLongitude, field, mapHasLoaded, mapRef]);

  const prescription = useMemo(
    () =>
      proPrescriptions
        .concat(field.features[0].properties.prescriptions)
        .find((rx) => rx.id === prescriptionId),
    [field, prescriptionId],
  );

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map) {
      const zonesId = 'prescription-zones';
      const amountId = 'prescription-zones-amounts';
      const fieldId = 'field-background';
      if (map.getLayer(zonesId)) {
        map.removeLayer(zonesId);
        map.removeSource(zonesId);
      }
      if (map.getLayer(amountId)) {
        map.removeLayer(amountId);
        map.removeSource(amountId);
      }
      if (map.getLayer(fieldId)) {
        map.removeLayer(fieldId);
        map.removeSource(fieldId);
      }
      if (zones && !prescription?.geojson_uri) {
        const formattedZones = formatZonesWithOpacity(zones);
        const newFeatureCollection = featureCollection(formattedZones);
        const source = {
          type: 'geojson',
          data: newFeatureCollection,
        } as GeoJSONSourceRaw;

        const pointSource = {
          type: 'geojson',
          data: {
            ...newFeatureCollection,
            features: newFeatureCollection.features.map((f) => ({
              ...f,
              geometry: center(f.geometry).geometry,
            })),
          },
        } as GeoJSONSourceRaw;
        map.addLayer({
          id: zonesId,
          type: 'fill',
          source,
          paint: {
            'fill-color': {
              property: 'fillPercent',
              stops: PRESCRIPTION_COLORS,
            },
            'fill-outline-color': COLORS.white,
          },
        });
        map.addLayer({
          id: amountId,
          type: 'symbol',
          source: pointSource,
          layout: {
            'text-field': ['get', AMOUNT_KEY],
            'text-justify': 'center',
            'text-size': 12,
          },
          paint: {
            'text-color': COLORS.white,
          },
        });
      }
    }
  }, [field, mapHasLoaded, mapRef, prescription, zones]);

  useEffect(() => {
    const map = mapRef.current;
    if (mapHasLoaded && map && prescription) {
      const mapId = `prescriptions-${prescription.field_id}`;
      if (prescription.pro_density) {
        const style = map.getStyle();
        style.layers.forEach((layer) => {
          if (layer.id.startsWith(mapId)) {
            map.removeLayer(layer.id);
          }
        });
        Object.keys(style.sources).forEach((source) => {
          if (source.startsWith(mapId)) {
            map.removeSource(source);
          }
        });
        if (prescription.machine_data && mode === YIELD_MAP_MODE) {
          addMachineDataToMap(
            prescription.machine_data,
            map,
            mapId,
            prescription,
            setPopupInfo,
            getYieldPopupContent(field, language),
          );
        } else if (prescription.composite_imagery && mode === IMAGERY_MAP_MODE) {
          addImageryDataToMap(
            prescription.composite_imagery,
            map,
            mapId,
            setPopupInfo,
            getNdviPopupContent(field, language),
          );
        } else {
          addProPrescriptionToMap(
            prescription,
            map,
            mapId,
            setPopupInfo,
            getPopupContent(field, language, prescription),
            (prescription.machine_data_id || prescription.composite_imagery_id) &&
              mode !== APPLICATION_RATE_MODE
              ? RAW_VALUE_KEY
              : AMOUNT_KEY,
          );
        }
      } else {
        const zonesId = 'prescription-zones-outline';
        if (map.getLayer(zonesId)) {
          map.removeLayer(zonesId);
          map.removeSource(zonesId);
        }
        if (zones && selectedZone) {
          const selectedZoneFeature = zones.find((z) => z.properties.id === selectedZone);
          const newFeatureCollection = featureCollection(
            selectedZoneFeature ? [selectedZoneFeature] : [],
          );
          const source = {
            type: 'geojson',
            data: newFeatureCollection,
          } as GeoJSONSourceRaw;

          map.addLayer({
            id: zonesId,
            type: 'line',
            source,
            paint: { 'line-color': COLORS.darkBlue, 'line-width': 2 },
          });
        }
      }
    }
  }, [field, mapHasLoaded, mapRef, prescription, zones, selectedZone, mode]);

  const recenterMap = () => {
    setViewport(initialViewport);
    if (mapRef.current) {
      mapRef.current.setZoom(initialViewport.zoom);
      mapRef.current.setCenter([initialViewport.longitude, initialViewport.latitude]);
    }
  };

  const anchor = useMemo(() => {
    if (mapRef.current && popupInfo) {
      const mapCenter = mapRef.current.getCenter();
      if (popupInfo.lat > mapCenter.lat && popupInfo.lng > mapCenter.lng) {
        return 'bottom-right';
      }
      if (popupInfo.lat > mapCenter.lat && popupInfo.lng < mapCenter.lng) {
        return 'bottom-left';
      }
      if (popupInfo.lat < mapCenter.lat && popupInfo.lng > mapCenter.lng) {
        return 'top-right';
      }
      return 'top-left';
    }
    return 'bottom';
  }, [mapRef, popupInfo]);

  const isSeedPrescription = prescription?.seed !== null;

  const legendMetadata = (() => {
    if (!prescription) {
      return {
        colorStops: [],
        marks: [],
        title: '',
      };
    }
    if ([YIELD_MAP_MODE, YIELD_ZONE_MODE].includes(mode)) {
      return {
        colorStops: [RISK_FILL_COLORS.HIGH_RISK, RISK_FILL_COLORS.LOW_RISK],
        marks: [
          Math.round(prescription.source_layer_max || DEFAULT_HARVEST_MAX),
          Math.round(prescription.source_layer_min || DEFAULT_HARVEST_MIN),
        ],
        title: getUnitBuAc(field.features[0].properties.acreage_unit, language),
      };
    }
    if ([IMAGERY_MAP_MODE, IMAGERY_ZONE_MODE].includes(mode) && prescription.composite_imagery) {
      const ndviMapSteps = getNdviMapSteps(prescription.composite_imagery);
      const colors = ndviMapSteps.filter((step) => typeof step === 'string') as string[];
      const marks = ndviMapSteps.filter((step) => typeof step === 'number') as number[];
      return {
        colorStops: colors,
        marks: marks.map((mark) => mark.toFixed(2)).reverse(),
        title: getString(NDVI_KEY, language),
      };
    }
    return {
      colorStops: [
        PRESCRIPTION_COLORS[0][1] as string,
        PRESCRIPTION_COLORS[PRESCRIPTION_COLORS.length - 1][1] as string,
      ],
      marks: [prescription.amount_range[1], prescription.amount_range[0]],
      title: isSeedPrescription
        ? capitalize(getString('seeds', language))
        : getUnitLbsAc(field.features[0].properties.acreage_unit),
    };
  })();

  const applicationRateI18nKey = isSeedPrescription ? 'plantingRate' : 'applicationRate';

  return (
    <Box className={styles.Wrapper}>
      <Box className={styles.MapWrapper} ref={wrapperRef}>
        <Box ref={mapContainerRef} className={styles.MapWrapper} />
        {prescription && (
          <LegendWrap
            colorStops={legendMetadata.colorStops}
            marks={legendMetadata.marks}
            maxWidth={60}
            showPlus={false}
            title={legendMetadata.title}
          />
        )}
        {popupInfo && mapRef.current && (
          <Popup
            {...popupInfo}
            map={mapRef.current}
            anchor={anchor}
            onClose={() => setPopupInfo(null)}
          >
            {popupInfo.content}
          </Popup>
        )}
        <MapScriptInfo prescription={prescription} field={field} />
        {prescription?.machine_data && (
          <SegmentedControl
            className={styles.ModeToggle}
            color={COLORS.darkBlue}
            data={[
              {
                label: getString(applicationRateI18nKey, language),
                value: APPLICATION_RATE_MODE,
              },
              { label: getString('yieldZones', language), value: YIELD_ZONE_MODE },
              { label: getString('yieldMap', language), value: YIELD_MAP_MODE },
            ]}
            onChange={setMode}
            value={mode}
          />
        )}
        {prescription?.composite_imagery && (
          <SegmentedControl
            className={styles.ModeToggle}
            color={COLORS.darkBlue}
            data={[
              {
                label: getString(applicationRateI18nKey, language),
                value: APPLICATION_RATE_MODE,
              },
              { label: getString('imageryZones', language), value: IMAGERY_ZONE_MODE },
              { label: getString('imageryMap', language), value: IMAGERY_MAP_MODE },
            ]}
            onChange={setMode}
            value={mode}
          />
        )}
        <Button variant="white" className={styles.Recenter} onClick={recenterMap}>
          {getString('recenter', language)}
        </Button>
      </Box>
    </Box>
  );
};

export default Map;
