import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, ComboboxItem, Flex, Input, Select, Stack, Text } from '@mantine/core';
import turfBbox from '@turf/bbox';
import mapboxgl, { GeoJSONSourceRaw, LngLatBoundsLike } from 'mapbox-gl';

import { COLORS } from 'constants/colors';
import { ENGLISH } from 'constants/languages';
import { FIELD_OUTLINE, MAPBOX_FIT_PARAMS } from 'constants/mapbox';

import { formatDateMonthDayYear } from 'util/date';
import { isProduction } from 'util/request';
import { sortByValue } from 'util/sortByName';
import showToast from 'actions/toastActions';
import fetchFieldGeometry from 'store/fields/requests';
import { FieldType, MachineDataType } from 'store/fields/types';
import useMapboxGl from 'common/MapHooks';
import { ViewPortProps } from 'common/Maps/types';

const HarvestContainer = () => {
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef(null);
  const [fieldId, setFieldId] = useState('');
  const [field, setField] = useState<FieldType>();
  const [layers, setLayers] = useState<MachineDataType[]>([]);
  const [value, setValue] = useState<ComboboxItem | null>();

  const [viewport, setViewport] = useState<ViewPortProps>({
    latitude: 0,
    longitude: 0,
    zoom: 0,
    width: 0,
    height: 0,
  });

  const getField = async () => {
    try {
      if (fieldId) {
        const response: FieldType = await fetchFieldGeometry(Number(fieldId));
        setField(response);
        const harvestFiles = sortByValue(
          response.features[0].properties.harvest_data_files,
          'start_time',
        ).reverse();
        setLayers(harvestFiles);
        setValue(
          harvestFiles.length
            ? {
                label: formatDateMonthDayYear(harvestFiles[0].start_time, ENGLISH),
                value: harvestFiles[0].processed_geojson_uri,
              }
            : null,
        );
      }
    } catch (error) {
      showToast('failed to fetch field', 'error');
    }
  };

  useEffect(() => {
    if (isProduction) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (mapRef.current && field) {
      const map = mapRef.current;

      if (map.getLayer(FIELD_OUTLINE)) {
        map.removeLayer(FIELD_OUTLINE);
        map.removeSource(FIELD_OUTLINE);
      }

      const bbox = turfBbox(field) as LngLatBoundsLike;
      map.fitBounds(bbox, MAPBOX_FIT_PARAMS);

      map.addLayer({
        id: FIELD_OUTLINE,
        type: 'line',
        source: {
          type: 'geojson',
          data: field,
        },
        paint: { 'line-color': COLORS.white, 'line-width': 2 },
      });
    }
  }, [mapRef.current, field]);

  useEffect(() => {
    if (mapRef.current && value) {
      const map = mapRef.current;

      const layerName = 'file-layer';

      if (map.getLayer(layerName)) {
        map.removeLayer(layerName);
        map.removeSource(layerName);
      }

      map.addLayer({
        id: layerName,
        type: 'circle',
        source: {
          type: 'geojson',
          data: value.value,
        } as GeoJSONSourceRaw,
        paint: {
          'circle-color': [
            'interpolate',
            ['linear'],
            ['get', 'dryVolumePerArea'],
            0,
            COLORS.red,
            200,
            COLORS.lightGreen,
          ],
          'circle-radius': 3,
        },
      });
    }
  }, [value, mapRef.current]);

  useMapboxGl(mapContainerRef, mapRef, null, viewport, setViewport, () => {}, true);

  const selectorOptions: ComboboxItem[] = layers.map((layer) => ({
    value: layer.processed_geojson_uri,
    label: formatDateMonthDayYear(layer.start_time, ENGLISH),
  }));

  return (
    <Stack h="100%" w="100%">
      <Text>Harvest Layer Visualizer</Text>
      <Flex align="flex-end">
        <Input.Wrapper label="Field ID">
          <Input onChange={(event) => setFieldId(event.currentTarget.value)} value={fieldId} />
        </Input.Wrapper>
        <Button disabled={!fieldId} ml="md" onClick={getField}>
          Fetch
        </Button>
      </Flex>
      <Select
        data={selectorOptions}
        value={value ? value.value : null}
        onChange={(_value, option) => setValue(option)}
      />
      <Box w="100%" bg="red" h="50rem">
        <Box
          ref={wrapperRef}
          style={{
            flex: 1,
            position: 'relative',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            ref={mapContainerRef}
            style={{
              flex: 1,
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default HarvestContainer;
