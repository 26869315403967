import { Stack, Table, Text } from '@mantine/core';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { COLORS, LIGHT_GREY_BG } from 'constants/colors';

import useBroswerLanguage from 'util/hooks/useLanguage';
import { defaultPricingQueryOptions } from 'util/queryOptions';
import { getString } from 'strings/translation';
import { SkuType } from 'store/pricing/types';
import CenteredLoader from 'common/CenteredLoader';

import DefaultSkuPriceRow from './DefaultSkuPriceRow';
import DefaultSkuPrices from './DefaultSkuPrices';

interface Props {
  skuQuery: UseQueryResult<SkuType[], Error>;
}

const PricingTable = ({ skuQuery }: Props) => {
  const language = useBroswerLanguage();
  const defaultPricingQuery = useQuery(defaultPricingQueryOptions);

  if (defaultPricingQuery.isPending || skuQuery.isPending) {
    return <CenteredLoader />;
  }

  const analysisPrices = Object.values(defaultPricingQuery.data || {})?.reduce(
    (acc, price) => {
      acc[price.sku.type][price.sku.id] = price;
      return acc;
    },
    {
      analysis: {},
      sampling: {},
      scan: {},
      adcp: {},
      pro_map_generation: {},
      till_rx_map_generation: {},
    },
  );

  const getDefaultRow = (headerStr: string, backgroundColor?: string, textColor?: string) => (
    <Table.Tr bg={backgroundColor}>
      <Table.Td>
        <Text size="sm" c={textColor}>
          {headerStr}
        </Text>
      </Table.Td>
      <Table.Td />
      <Table.Td />
      <Table.Td />
      <Table.Td />
      <Table.Td />
      <Table.Td />
      <Table.Td />
      <Table.Td />
    </Table.Tr>
  );

  return (
    <Stack>
      <Table withTableBorder withColumnBorders stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{getString('productName', language)}</Table.Th>
            <Table.Th>{getString('priceType', language)}</Table.Th>
            <Table.Th>{getString('price', language)}</Table.Th>
            <Table.Th>{getString('listPrice', language)}</Table.Th>
            <Table.Th>{getString('minDensity', language)}</Table.Th>
            <Table.Th>{getString('maxDensity', language)}</Table.Th>
            <Table.Th>{getString('minScanDensity', language)}</Table.Th>
            <Table.Th>{getString('maxScanDensity', language)}</Table.Th>
            <Table.Th>{getString('notes', language)}</Table.Th>
          </Table.Tr>
          {getDefaultRow('*NEW*', LIGHT_GREY_BG)}
          <DefaultSkuPriceRow skuOptions={skuQuery.data} />
        </Table.Thead>
        <Table.Tbody>
          {Object.keys(analysisPrices).map((priceType) => (
            <>
              {getDefaultRow(priceType.toUpperCase(), COLORS.greyBlue, COLORS.white)}
              {Object.keys(analysisPrices[priceType]).map((skuId) => (
                <DefaultSkuPrices
                  key={skuId}
                  skuId={skuId}
                  defaultPriceType={analysisPrices[priceType][skuId]}
                  skuOptions={skuQuery.data}
                />
              ))}
            </>
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
  );
};

export default PricingTable;
