import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import { ActionIcon, Loader, TextInput } from '@mantine/core';

import { useProjectsTranslations } from './hooks';

type Props = {
  initialValue?: string;
  nameFilterIsLoading: boolean;
  translations: ReturnType<typeof useProjectsTranslations>;
  onChange: (query: string) => void;
};

const ProjectNameFilterInput = ({
  nameFilterIsLoading,
  onChange,
  translations,
  initialValue = '',
}: Props) => {
  const [nameFilter, setNameFilter] = useState(initialValue);

  const handleChange = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(currentTarget.value);
    onChange(currentTarget.value);
  };

  const handleClear = () => {
    setNameFilter('');
    onChange('');
  };

  return (
    <TextInput
      label={translations.project}
      placeholder={`${translations.search} ${translations.projects}`}
      value={nameFilter}
      onChange={handleChange}
      leftSection={<FaSearch size={16} />}
      rightSection={
        nameFilterIsLoading ? (
          <Loader size={16} />
        ) : (
          <ActionIcon size="sm" variant="transparent" c="dimmed" onClick={handleClear}>
            <MdClear size={14} />
          </ActionIcon>
        )
      }
    />
  );
};

export default ProjectNameFilterInput;
