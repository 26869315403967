import { PaginatedResponseType } from 'util/generalTypes';
import {
  handleFileAttachment,
  handleJsonResponse,
  requestDelete,
  requestGet,
  requestGetFile,
  requestPatch,
  requestPost,
  requestPostFile,
  requestPut,
  SERVICE_URL,
} from 'util/request';
import { queryStringify } from 'util/stringUtils';

import {
  CarbonCollection,
  CarbonSampleFeature,
  CarbonSamplePutPayload,
  CarbonSamplingPlanType,
  CarbonSamplingProgress,
  CreateCarbonCollectionPayload,
  CreateFieldGatePayload,
  CreateProjectPayloadType,
  CreateSamplingPlanPayloadType,
  DefaultSamplingPlanConfigResponse,
  EditProjectPayloadType,
  FieldGate,
  GetProjectsPayloadType,
  ProjectSamplingCountResponse,
  ProjectType,
} from './types';

export const getPaginatedProjects = async (
  params: GetProjectsPayloadType = {},
): Promise<PaginatedResponseType<{ items: ProjectType[] }>> =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/carbon_projects_paginated?${queryStringify(params)}`),
  );

export const getProjectById = async (id: number): Promise<ProjectType> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/carbon_project/${id}`));

export const createProject = async (body: CreateProjectPayloadType) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/carbon_project/`, { body }));

export const editProject = async (
  projectId: string | number,
  body: Partial<EditProjectPayloadType>,
) => handleJsonResponse(await requestPatch(`${SERVICE_URL}/carbon_project/${projectId}`, { body }));

/** Number of active jobs for the given project */
export const getProjectRunningSamplingPlanCount = async (
  projectId: string | number,
): Promise<ProjectSamplingCountResponse> =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/carbon_sample_plans/running_count/${projectId}`),
  );

export const createSamplingPlan = async (body: CreateSamplingPlanPayloadType) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/carbon_sample_plan/`, { body }));

export const getSamplingPlanById = async (id: string | number): Promise<CarbonSamplingPlanType> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/carbon_sample_plan/${id}`));

export const getSamplingPlansByProjectId = async (
  projectId: string | number,
): Promise<CarbonSamplingPlanType[]> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/carbon_sample_plans/${projectId}`));

export const getDefaultSamplingPlanConfig = async (): Promise<DefaultSamplingPlanConfigResponse> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/carbon_sample_plan_default_config/`));

export const deleteCarbonSample = async (sampleId: number) =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/carbon_sample/${sampleId}`));

export const moveCarbonSample = async (sampleId: number, body: CarbonSamplePutPayload) =>
  handleJsonResponse(await requestPut(`${SERVICE_URL}/carbon_sample/${sampleId}`, { body }));

export const getCarbonSamples = async (
  samplingPlanId: string | number,
): Promise<CarbonSampleFeature[]> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/carbon_samples/${samplingPlanId}`));

export const getCarbonCollection = async (
  collectionId: string | number,
): Promise<CarbonCollection> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/carbon_collection/${collectionId}`));

export const createCarbonCollection = async (body: CreateCarbonCollectionPayload) =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/carbon_collection/`, { body }));

export const updateCarbonCollectionFields = async (collectionUuid: string, field_ids: number[]) =>
  handleJsonResponse(
    await requestPut(`${SERVICE_URL}/carbon_collection_fields/${collectionUuid}`, {
      body: { field_ids },
    }),
  );

export const getFieldGates = async (fieldId: string | number): Promise<FieldGate[]> =>
  handleJsonResponse(await requestGet(`${SERVICE_URL}/field_gates/${fieldId}`));

export const deleteFieldGate = async (gateId: number): Promise<{ msg: string }> =>
  handleJsonResponse(await requestDelete(`${SERVICE_URL}/field_gate/${gateId}`));

export const createFieldGate = async (payload: CreateFieldGatePayload): Promise<FieldGate> =>
  handleJsonResponse(await requestPost(`${SERVICE_URL}/field_gate/`, { body: payload }));

export const createFieldGatesViaFile = async (fieldId: number, file: File): Promise<FieldGate[]> =>
  handleJsonResponse(
    await requestPostFile(`${SERVICE_URL}/field_gate_upload/${fieldId}`, { file }),
  );

export const download811csv = async (samplingPlanId: number) =>
  handleFileAttachment(
    await requestGetFile(`${SERVICE_URL}/carbon_sample_plan/csv_811/${samplingPlanId}`),
  );

export const downloadSoilCollectorExport = async (samplingPlanId: number) =>
  handleFileAttachment(
    await requestGetFile(
      `${SERVICE_URL}/carbon_sample_plan/carbon_soil_collector_export/${samplingPlanId}`,
    ),
  );

export const getSamplingPlanProgressLog = async (
  samplingPlanId: string,
): Promise<CarbonSamplingProgress> =>
  handleJsonResponse(
    await requestGet(`${SERVICE_URL}/carbon_sample_plan_status/${samplingPlanId}`),
  );
